/*
|-------------------------------------------------------------------------------
| VUEX modules/setting.store.js
|-------------------------------------------------------------------------------
| The Vuex data store for the Setting
*/

/* Import Store */
import store from "../index";

/* Constant Display Mode */
const cDisplayMode = {
  lastest: "lastest",
  history: "history",
};

/* Constant Role Type */
const cRoleType = {
  group:{
    asan: "asan"
  },
  mapAdmin: "map_admin",
  superAdmin: "super_admin",
  admin: "admin",
  driver: "driver",
  passenger: "passenger",
  bus_driver: "bus_driver",
  bus_passenger: "bus_passenger",
};

/* Default Time From To */
const cDefaultTimeFromTo = {
  from: "00:00",
  to: "23:59",
};

/* Constant Current Reload State */
const cStateReloadMainPage = {
  initial: "initial",
  lastestLocation: "lastestLocation",
  historyContentDialog: "historyContentDialog",
  reportContentDialog: "reportContentDialog",
  settingContentDialog: "settingContentDialog",
};

/* Constant State History */
const cStateHistory = {
  tackingMode: {
    on: "on",
    off: "off",
  },
};

/* Constant State History */
const cDefaultTimeAttendance = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

const cDefaultOverspeed = [
  "off",
  "80 km/hr",
  "100 km/hr",
  "120 km/hr",
  "140 km/hr",
];

const cDefaultOverspeedValue = {
  off: 0,
  "80 km/hr": 80,
  "100 km/hr": 100,
  "120 km/hr": 120,
  "140 km/hr": 140,
};

const cDefaultOverspeedKey = {
  0: "off",
  80: "80 km/hr",
  100: "100 km/hr",
  120: "120 km/hr",
  140: "140 km/hr",
};

const cDefaultOverstay = [
  "off",
  "30 mins",
  "1 hr",
  "2 hrs",
  "3 hrs",
  "4 hrs",
  "8 hrs",
];

const cDefaultOverstayValue = {
  off: 0,
  "30 mins": 30,
  "1 hr": 60,
  "2 hrs": 120,
  "3 hrs": 180,
  "4 hrs": 240,
  "8 hrs": 480,
};

const cDefaultOverstayKey = {
  0: "off",
  30: "30 mins",
  60: "1 hr",
  120: "2 hrs",
  180: "3 hrs",
  240: "4 hrs",
  480: "8 hrs",
};

const cDefaultOptionOverspeedDetection = [
  "off",
  "30 Km/hr",
  "40 Km/hr",
  "50 Km/hr",
  "60 Km/hr",
  "70 Km/hr",
  "80 Km/hr",
  "90 Km/hr",
  "100 Km/hr",
  "110 Km/hr",
  "120 Km/hr",
];

const cDefaultOptionOverspeedDetectionValue = {
  off: 0,
  "30 Km/hr": 30,
  "40 Km/hr": 40,
  "50 Km/hr": 50,
  "60 Km/hr": 60,
  "70 Km/hr": 70,
  "80 Km/hr": 80,
  "90 Km/hr": 90,
  "100 Km/hr": 100,
  "110 Km/hr": 110,
  "120 Km/hr": 120,
};

const cDefaultOptionOverspeedDetectionKey = {
  0: "off",
  30: "30 Km/hr",
  40: "40 Km/hr",
  50: "50 Km/hr",
  60: "60 Km/hr",
  70: "70 Km/hr",
  80: "80 Km/hr",
  90: "90 Km/hr",
  100: "100 Km/hr",
  110: "110 Km/hr",
  120: "120 Km/hr",
};

const cDefaultOptionAreaOffsetGeoFence = [
  "off",
  "5 meters",
  "10 meters",
  "15 meters",
  "20 meters",
  "30 meters",
  "40 meters",
  "50 meters",
];

/* Manage GeoFence In/Out Setting */
const cDefaultOptionAreaOffsetGeoFenceValue = {
  off: 0,
  "5 meters": 5,
  "10 meters": 10,
  "15 meters": 15,
  "20 meters": 20,
  "30 meters": 30,
  "40 meters": 40,
  "50 meters": 50,
};

const cDefaultOptionAreaOffsetGeoFenceKey = {
  0: "off",
  5: "5 meters",
  10: "10 meters",
  15: "15 meters",
  20: "20 meters",
  30: "30 meters",
  40: "40 meters",
  50: "50 meters",
};

const cDefaultOptionAreaTypeGeoFenceByGroupMap = [
  "Rectangle",
  "Custom",
  "Free form",
];
const cDefaultOptionAreaTypeGeoFenceByGroupCustomer = [
  "Rectangle",
  "Custom",
];

const cDefaultOptionAreaTypeGeoFenceValueByGroupMap = {
  Rectangle: "Rect",
  "Custom": "Rect-Cust",
  "Free form": "Cust",
};

const cDefaultOptionAreaTypeGeoFenceValueByGroupCustomer = {
  Rectangle: "Rect",
  "Custom": "Rect-Cust",
};

const cDefaultOptionAreaTypeGeoFenceKeyByGroupMap = {
  Rect: "Rectangle",
  "Rect-Cust": "Custom",
  Cust: "Free form",
};

const cDefaultOptionAreaTypeGeoFenceKeyByGroupCustomer = {
  Rect: "Rectangle",
  "Rect-Cust": "Custom",
};

const cDefaultOptionDetectionOffsetGeoFence = [
  "1 times",
  "2 times",
  "3 times",
  "4 times",
  "5 times",
];

const cDefaultOptionDetectionOffsetGeoFenceValue = {
  "1 times": 1,
  "2 times": 2,
  "3 times": 3,
  "4 times": 4,
  "5 times": 5,
};

const cDefaultOptionDetectionOffsetGeoFenceKey = {
  1: "1 times",
  2: "2 times",
  3: "3 times",
  4: "4 times",
  5: "5 times",
};

const cDefaultOptionOverspeedInGeoFence = [
  "off",
  "10 km/hr",
  "20 km/hr",
  "30 km/hr",
  "40 km/hr",
  "50 km/hr",
  "60 km/hr",
  "70 km/hr",
  "80 km/hr",
];

const cDefaultOptionOverspeedInGeoFenceValue = {
  off: 0,
  "10 km/hr": 10,
  "20 km/hr": 20,
  "30 km/hr": 30,
  "40 km/hr": 40,
  "50 km/hr": 50,
  "60 km/hr": 60,
  "70 km/hr": 70,
  "80 km/hr": 80,
};

const cDefaultOptionOverspeedInGeoFenceKey = {
  0: "off",
  10: "10 km/hr",
  20: "20 km/hr",
  30: "30 km/hr",
  40: "40 km/hr",
  50: "50 km/hr",
  60: "60 km/hr",
  70: "70 km/hr",
  80: "80 km/hr",
};

/* Constant State History */
const cDefaultOffsetGeoFenceSetting = {
  limit: {
    distance: 0.002,
  },
};

const mDefaultState = () => {
  return {
    displayMode: cDisplayMode.lastest,
    currentStateReloadMainPage: cStateReloadMainPage.initial,
    stateHistory: {
      tackingMode: cStateHistory.tackingMode.off,
    },
  };
};

/* Defines the state being monitored for the module. */
const state = mDefaultState();

/* Defines the getters used by the module */
const getters = {
  /* Display Mode */
  displayMode: (state) => state.displayMode,
  lastestMode: () => cDisplayMode.lastest,
  historyMode: () => cDisplayMode.history,
  /* Role Type */
  mapAdmin: () => cRoleType.mapAdmin,
  superAdmin: () => cRoleType.superAdmin,
  admin: () => cRoleType.admin,
  driver: () => cRoleType.driver,
  passenger: () => cRoleType.passenger,
  /* Group */
  groupAsan: () => cRoleType.group.asan,
  /* Default Time From To */
  defaultTimeFrom: () => cDefaultTimeFromTo.from,
  defaultTimeTo: () => cDefaultTimeFromTo.to,
  /* State History */
  stateHistory: (state) => state.stateHistory,
  stateHistoryTackingModeOn: () => cStateHistory.tackingMode.on,
  stateHistoryTackingModeOff: () => cStateHistory.tackingMode.off,
  /* Current State Reload */
  currentStateReloadMainPage: (state) => state.currentStateReloadMainPage,
  stateInitialMainPage: () => cStateReloadMainPage.initial,
  stateLastestLocationMainPage: () => cStateReloadMainPage.lastestLocation,
  stateHistoryContentDialogMainPage: () =>
    cStateReloadMainPage.historyContentDialog,
  stateReportContentDialogMainPage: () =>
    cStateReloadMainPage.reportContentDialog,
  stateSettingContentDialogMainPage: () =>
    cStateReloadMainPage.settingContentDialog,
  /* Default Time Attendance */
  settingTimeAttendace: () => cDefaultTimeAttendance,
  /* Default Notification */
  settingOverspeed: () => cDefaultOverspeed,
  settingOverstay: () => cDefaultOverstay,
  settingOverspeedValue: () => cDefaultOverspeedValue,
  settingOverstayValue: () => cDefaultOverstayValue,
  settingOverspeedKey: () => cDefaultOverspeedKey,
  settingOverstayKey: () => cDefaultOverstayKey,
  settingOptionOverspeedDetection: () => cDefaultOptionOverspeedDetection,
  settingOptionOverspeedDetectionValue: () =>
    cDefaultOptionOverspeedDetectionValue,
  settingOptionOverspeedDetectionKey: () => cDefaultOptionOverspeedDetectionKey,
  defaultOptionOverspeedDetection: () => cDefaultOptionOverspeedDetection[0],
  /* Default GeoFence */
  settingOptionAreaOffsetGeoFence: () => cDefaultOptionAreaOffsetGeoFence,
  settingOptionAreaOffsetGeoFenceValue: () =>
    cDefaultOptionAreaOffsetGeoFenceValue,
  settingOptionAreaOffsetGeoFenceKey: () => cDefaultOptionAreaOffsetGeoFenceKey,
  settingOptionAreaTypeGeoFence: () => {
    let _username = store.getters["username"];
    let _customGroup = store.getters["userCustomGroup"];
    if ("map" == _customGroup || "micware" == _username)
      return cDefaultOptionAreaTypeGeoFenceByGroupMap;
    return cDefaultOptionAreaTypeGeoFenceByGroupCustomer;
  },
  settingOptionAreaTypeGeoFenceValue: () => {
    let _username = store.getters["username"];
    let _customGroup = store.getters["userCustomGroup"];
    if ("map" == _customGroup || "micware" == _username)
      return cDefaultOptionAreaTypeGeoFenceValueByGroupMap;
    return cDefaultOptionAreaTypeGeoFenceValueByGroupCustomer;
  },
  settingOptionAreaTypeGeoFenceKey: () => {
    let _username = store.getters["username"];
    let _customGroup = store.getters["userCustomGroup"];
    if ("map" == _customGroup || "micware" == _username)
      return cDefaultOptionAreaTypeGeoFenceKeyByGroupMap;
    return cDefaultOptionAreaTypeGeoFenceKeyByGroupCustomer;
  },
  settingOptionDetectionOffsetGeoFence: () =>
    cDefaultOptionDetectionOffsetGeoFence,
  settingOptionDetectionOffsetGeoFenceValue: () =>
    cDefaultOptionDetectionOffsetGeoFenceValue,
  settingOptionDetectionOffsetGeoFenceKey: () =>
    cDefaultOptionDetectionOffsetGeoFenceKey,
  settingDefaultGeoFenceLimitDistanOffset: () =>
    cDefaultOffsetGeoFenceSetting.limit.distance,
  settingOptionOverspeedInGeoFence: () => cDefaultOptionOverspeedInGeoFence,
  settingOptionOverspeedInGeoFenceValue: () =>
    cDefaultOptionOverspeedInGeoFenceValue,
  settingOptionOverspeedInGeoFenceKey: () =>
    cDefaultOptionOverspeedInGeoFenceKey,
};

/* Defines the mutations used */
const mutations = {
  displayMode: (state, displayMode) => (state.displayMode = displayMode),
  currentStateReloadMainPage: (state, currentStateReloadMainPage) =>
    (state.currentStateReloadMainPage = currentStateReloadMainPage),
  /* State History */
  stateHistoryTackingMode: (state, tackingMode) =>
    (state.stateHistory.tackingMode = tackingMode),
  /* Reset State */
  resetState: (state) => Object.assign(state, mDefaultState()),
};

/* Export */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
};

/* END OF FILE */
